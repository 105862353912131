import React from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Core/Seo";
import { graphql } from "gatsby";
import IntroBlock from "../components/Category/IntroBlock";
import PortfolioGrid from "../components/Category/PortfolioGrid";

const categoryTemplate = (props) => {
  const page = props.data.page;
  const works = props.data.works;
  const { metaTitle, metaDescription, socialMediaImage } = page.seo;

  const path = props.location.pathname;

  let sharingImage = false;

  if (socialMediaImage) {
    sharingImage = socialMediaImage.file.url;
  }

  return (
    <Layout>
      <Seo
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        path={path}
        image={sharingImage}
      />
      <div>
        <IntroBlock />
        <PortfolioGrid works={works.nodes} />
      </div>
    </Layout>
  );
};

export default categoryTemplate;

export const pageQuery = graphql`
  query CategoryTemplateQuery($slug: String!) {
    page: contentfulCategory(slug: { eq: $slug }) {
      seo {
        ...seoFragment
      }
    }
    works: allContentfulPortfolioEntry(
      filter: { category: { slug: { eq: $slug } } }
      sort: { order: DESC, fields: createdAt }
    ) {
      nodes {
        ...portfolioCardFragment
      }
    }
  }
`;
